<style lang="sass">

  #divAppHdr {
    background-color: #101328;
    padding:          10px 8px 0;
    height:           55px;
    color:            gold;
    box-shadow:       2px 5px 6px black;
    border:           1px solid #123;
    border-radius:    3px;

    input {
      width:            10em;
      color:            white;
    }
    div.logo {
      position:         relative;
      img {
        width:            32px;
      }
      span {
        display:          inline-block;
        position:         absolute;
        left:             55px;
        padding-left:     4px;
      }
      span.me {
        border-bottom:      1px solid #543;
        letter-spacing:     3px;
      }
      span.asd {
        font-size:          0.75rem;
        top:                1.7em;
      }
    }
    div.filter {
      padding-right:    30px;
    }
    div.tags {
      // position:           relative;
      div.tit {
        // position:           absolute;
        // top:                1px;
        // left:               30px;
        padding-left:     30px;
        ul.tagit {
          overflow:         hidden;
          background:       none;
          border:           none;
          margin:           0;
          padding:          0;
          li.tagit-choice {
            padding:          0px 16px 2px 4px;
            color:            #484858;
            background:       none;
            border-color:     #666;
            a.tagit-close {
              top:              8px;
              right:            0px;
            }
          }
          span.tagit-label {
            // font-size:        1.2rem;
            color:            white;
          }
          li.tagit-new {
            padding:            0;
            // border: 1px solid red;
            input {
              width:            64px;
              // height:           1.5em;
              border-bottom:    1px solid white;
            }
          }
        }
      }
    }
    .lang {
      padding-top:        3px;
      a {
        margin-right:     0.6em;
        font-size:        1.3rem;
      }
      a.selected {
        color:            gray;
      }
      a.active {
        cursor:           pointer;
      }
    }
  }

</style>

<template lang="jade">

  div#divAppHdr.grid-4
    div.logo.col
      img(src="/images/logo.png", align="middle")
      span.me André Leifert
      span.asd advanced systems design
    div.filter.col.text-align-right
      input(type="text", placeholder="filter", @keyup="filter", @blur="filter")
      i.material-icons.search(@click="filter") search
      i.material-icons.reset(@click="filter") close
    div.tags.col
      div.tit
        input.display-none
    div.lang.col.text-align-right
      a(v-for="lang in languages_supported", :data-lang="lang", @click="set_lang", :class="a_cls(lang)") {{ lang }}

</template>


<script lang="coffee">

  module.exports  =

    props: ['tags']

    data: ->
      app.cfg

    mounted: ->
      self = @
      $(@$el).find('div.tags input').tagit(
        autocomplete:
          # source:   @tags
          source: (term_obj, cb) ->
            term = term_obj.term.toLowerCase()
            tags = []
            self.tags.forEach((tag)->
              if tag.toLowerCase().indexOf(term) >=0 && tags.indexOf(tag) == -1
                tags.push(tag)
            )
            cb(tags)
        # allowSpaces:      true
          minLength:        0
        placeholderText:  'tag'

        afterTagAdded: (evt, ui)->
          self.retag(evt) unless ui.duringInitialization

        afterTagRemoved: (evt, ui)->
          self.retag(evt)
      )

    methods:
      a_cls: (lang) ->
        if lang == app.cfg.lang then 'selected' else 'active'
      set_lang: (evt) ->
        if $(evt.target).hasClass('active')
          $(@$el).find('div.lang a').toggleClass('active').toggleClass('selected')
          app.vm.$emit('set_lang', $(evt.target).data('lang'))
      filter: (evt) ->
        et                = evt.target
        $tf               = $(@$el).find('.filter input')
        debounce_cnt_down = 8

        unless et.className.indexOf('reset') < 0
          $tf.val('')
          debounce_cnt_down = 1
        if et.tagName == 'I' || evt.keyCode == 13 || evt.type == 'blur'
          debounce_cnt_down = 1
        @debounce(debounce_cnt_down)

      debounce: (cnt_down) ->
        @debounce_cnt_down  = cnt_down
        @debounce_ivl     ||= setInterval(=>
          if @debounce_cnt_down > 1
            @debounce_cnt_down--
          else
            clearInterval(@debounce_ivl)
            @debounce_ivl = null
            app.vm.$emit('set_filter', {text: $(@$el).find('.filter input').val()})
        , 100)

      retag: (evt) ->
        tags  = $(evt.target).tagit('assignedTags')
        # console.log('tags = %s', JSON.stringify(tags))
        app.vm.$emit('set_filter', {tags: tags})


</script>