<style lang="sass">

  .prj-entry {
    margin-top:       2em;
    letter-spacing:   1.5px;
    color:            #EEB;
    span,
    div.stack {
      // display:          inline-block;
      float: left;
    }

    .stack {
      vertical-align:   top;
      // height:           100%;
      background:       #101328;
      border-radius:    6px;
      padding:          8px 8px;
      margin:           0 0.75em;
      box-shadow:       2px 5px 6px black;
      max-width:        80%;
      line-height:      1.6em;
    }
    div.time-span {
      min-width:        143px;
    }
    .tags {
      // margin-top:       0.5em;
      font-style:       italic;
    }
    a.show {
      color:            #EED;
      // text-decoration:  none;
    }
    div.descr {
      display:          none;
      margin-top:       1em;
      font-size:        0.96rem;
      line-height:      1.2rem;
    }
  }

</style>

<template lang="jade">

  div.prj-entry
    div.stack(style="")
      div(v-html="prj.time_span").time-span
      a.show.more(href="#", @click="toggle_more_less") {{ show_more_less }}
    div.stack
      div(v-html="prj.industry + ' : ' + prj.project").title
      div(v-html="prj.environment").tags
      div(v-html="prj.text").descr
    div.clearfix

</template>


<script lang="coffee">

  german  =
    show:   'anzeigen'
    more:   'mehr'
    less:   'weniger'

  module.exports  =

    props: ['prj']

    data: ->
      expanded: false

    computed:
      show_more_less: ->
        which = if @.expanded then 'less' else 'more'
        txt   = if app.cfg.lang == 'en' then "show #{which}" else "#{german[which]} #{german.show}"


    methods:
      filter: (evt) ->
        et  = evt.target
        $tf = $(@$el).find('.filter input')
        unless et.className.indexOf('reset') < 0
          $tf.val('')
        if et.tagName == 'I' || evt.keyCode == 13 || evt.type == 'blur'
          console.log('filter = "%s"', $tf.val())
          # @reloadAsyncData()

      toggle_more_less: (evt) ->
        $a        = $(evt.target)
        $a.toggleClass(ml) for ml in ['more', 'less']
        $(@$el).find('div.descr').toggle()
        @.expanded = $a.hasClass('less')

</script>