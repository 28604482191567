<style lang="sass">



</style>

<template lang="jade">

  div.main
    app-header(:tags="all_tags")
    prj-entry(v-for="entry in filtered_prj_history", :prj="entry")

</template>


<script lang="coffee">

  AppHdr    = require './app-header.vue'
  PrjEntry  = require './prj-entry.vue'

  module.exports  =

    el:   '#page'

    components:
      'app-header': AppHdr
      'prj-entry':  PrjEntry


    data: ->
      prj_history: []

    computed:
      filtered_prj_history: ->
        res = []
        for entry in @prj_history
          res.push(entry) if @entry_passes(entry)
        # console.log('%d entries', res.length)
        res

      all_tags: ->
        @tags_of(@prj_history)


    mounted: ->
      @$on('set_lang',    $.proxy(@.on_set_lang, @))
      @$on('set_filter',  $.proxy(@.on_set_filter, @))
      @ld_model()

    methods:
      ld_model: ->
        self = @
        $.getJSON('/model?lang=' + app.cfg.lang).then((rsp) ->
          Vue.set(self, 'prj_history', rsp)
        )

      tags_of: (prj_history_or_entry) ->
        tags = []
        if Array.isArray(prj_history_or_entry) # complete history
          for entry in prj_history_or_entry
            for tag in @tags_of(entry)
              tags.push(tag) if tags.indexOf(tag) == -1
        else
          for tag in prj_history_or_entry.environment.split(/[,\/]/)
            tags.push(tag.trim().toLowerCase())
        tags

      entry_passes: (entry) ->
        passes = true
        if @filter_def
          if @filter_def.text
            txt     = @filter_def.text.toLowerCase()
            passes  = false
            for key, part of entry
              passes ||= part.toLowerCase().indexOf(txt) >= 0
          if @filter_def.tags && @filter_def.tags.length
            entry_tags = @tags_of(entry)
            for tag in @filter_def.tags
              in_any_entry_tag = false
              for entry_tag in entry_tags
                in_any_entry_tag ||= entry_tag.indexOf(tag) >= 0 # Ruby in Rails means Ruby, too
              passes &&= in_any_entry_tag
        passes

      on_set_lang: (lang) ->
        console.log('setting lang to %s', lang)
        app.cfg.lang = lang
        @ld_model()
      on_set_filter: (filter_def) ->
        @filter_def = Object.assign(@filter_def || {}, filter_def)
        Vue.set(@, 'prj_history', [].concat(@prj_history))


</script>